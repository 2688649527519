/**
 * Speaker Icon
 * @flow
 */
import React, { type Node } from 'react';
import styles from './Icons.module.scss';

const Speaker = (): Node => {
  return (
    <svg viewBox="0 0 7.6785607 7.6785612" className={styles.Icon}>
      <defs id="defs958" />
      <g id="layer1" transform="translate(-109.01591,-121.46111)">
        <g id="Speaker" style={{ display: 'inline' }} transform="matrix(0.35277777,0,0,-0.35277777,48.243199,171.0454)">
          <g id="g419" transform="translate(172.7691,129.6709)" style={{ display: 'inline' }}>
            <path
              d="m 0,0 c 0,5.734 4.648,10.383 10.383,10.383 5.734,0 10.383,-4.649 10.383,-10.383 0,-5.734 -4.649,-10.383 -10.383,-10.383 C 4.648,-10.383 0,-5.734 0,0"
              style={{
                fill: '#000000',
                fillOpacity: 1,
                fillRule: 'nonzero',
                stroke: 'none'
              }}
              id="path421"
            />
          </g>
          <g id="g423" transform="translate(172.7691,129.6709)">
            <path
              d="m 0,0 c 0,5.734 4.648,10.383 10.383,10.383 5.734,0 10.383,-4.649 10.383,-10.383 0,-5.734 -4.649,-10.383 -10.383,-10.383 C 4.648,-10.383 0,-5.734 0,0 Z"
              style={{
                fill: 'none',
                stroke: '#ffffff',
                strokeWidth: 1,
                strokeLinecap: 'butt',
                strokeLinejoin: 'miter',
                strokeMiterlimit: 10,
                strokeDasharray: 'none',
                strokeOpacity: 1
              }}
              id="path425"
            />
          </g>
          <g id="g459" transform="translate(185.6968,123.4961)">
            <path
              d="m 0,0 h -5.09 c -0.847,0 -1.534,0.687 -1.534,1.534 v 9.281 c 0,0.847 0.687,1.534 1.534,1.534 H 0 c 0.848,0 1.534,-0.687 1.534,-1.534 V 1.534 C 1.534,0.687 0.848,0 0,0"
              style={{
                fill: '#ffffff',
                fillOpacity: 1,
                fillRule: 'nonzero',
                stroke: 'none'
              }}
              id="path461"
            />
          </g>
          <g id="g463" transform="translate(180.8765,127.3066)">
            <path
              d="M 0,0 C 0,1.256 1.019,2.275 2.275,2.275 3.532,2.275 4.551,1.256 4.551,0 4.551,-1.257 3.532,-2.275 2.275,-2.275 1.019,-2.275 0,-1.257 0,0"
              style={{
                fill: '#000000',
                fillOpacity: 1,
                fillRule: 'nonzero',
                stroke: 'none'
              }}
              id="path465"
            />
          </g>
          <g id="g467" transform="translate(182.6372,126.792)">
            <path
              d="m 0,0 c -0.284,0.284 -0.284,0.744 0,1.028 0.284,0.284 0.745,0.284 1.029,0 C 1.313,0.744 1.313,0.284 1.029,0 0.745,-0.284 0.284,-0.284 0,0"
              style={{
                fill: '#ffffff',
                fillOpacity: 1,
                fillRule: 'nonzero',
                stroke: 'none'
              }}
              id="path469"
            />
          </g>
          <g id="g471" transform="translate(182.0142,132.583)">
            <path
              d="M 0,0 C 0,0.628 0.51,1.138 1.138,1.138 1.767,1.138 2.275,0.628 2.275,0 2.275,-0.629 1.767,-1.138 1.138,-1.138 0.51,-1.138 0,-0.629 0,0"
              style={{
                fill: '#000000',
                fillOpacity: 1,
                fillRule: 'nonzero',
                stroke: 'none'
              }}
              id="path473"
            />
          </g>
          <g id="g475" transform="translate(182.8951,132.3252)">
            <path
              d="m 0,0 c -0.143,0.143 -0.143,0.373 0,0.515 0.142,0.142 0.372,0.142 0.515,0 C 0.656,0.373 0.656,0.143 0.515,0 0.372,-0.142 0.142,-0.142 0,0"
              style={{
                fill: '#ffffff',
                fillOpacity: 1,
                fillRule: 'nonzero',
                stroke: 'none'
              }}
              id="path477"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Speaker;
