/**
 * Tshirt component
 * @flow
 */
import React, { type Node } from 'react';
import StripeIcon from '../icons/Stripe';
import styles from './Tshirt.module.scss';

type Props = {
  title: string,
  description: string,
  price: number,
  image: string,
  link: string
};

const Tshirt = (props: Props): Node => {
  const { title, description, price, image, link } = props;
  return (
    <div className={styles.Tshirt}>
      <div className={styles.Text}>
        <img src={image} alt={title} />
        <h2>{title}</h2>
        <p>{description}</p>
        <p className={styles.price}>${price} (incl GST) + shipping (Australia Only)</p>
        <a className={styles.button} href={link} target="_blank" rel="noreferrer">
          <StripeIcon /> Buy now
        </a>
      </div>
    </div>
  );
};

export default Tshirt;
