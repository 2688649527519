/**
 * TrackOutbound
 * @flow
 */
import React, { type Node } from 'react';
import type { Track } from '../../types/Types';
import styles from './Track.module.scss';

type Props = {
  track: Track
};

const TrackOutboundLinks = (props: Props): Node => {
  const { links } = props.track;

  return links && links.length ? (
    <div className={styles.TrackOutbound}>
      <div className={styles.inner}>
        <h2 className={styles.LinksHeading}>Listen on</h2>
        <ul className={styles.Links}>
          {links.map((link, index) => {
            const { title, path, icon } = link;

            return (
              <li key={index}>
                <a
                  href={path}
                  className={styles[title.replaceAll(' ', '')]}
                  target="_blank"
                  rel="noopener noreferrer"
                  title={title}
                >
                  {icon} {title}
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  ) : null;
};

TrackOutboundLinks.defaultProps = {
  track: { src: '', title: '' }
};

export default TrackOutboundLinks;
