/**
 * Data
 * @flow
 */
import React from 'react';
import Spotify from '../components/icons/Spotify';
import { AppleMusic, Itunes } from '../components/icons/AppleMusic';
import YouTubeMusic from '../components/icons/YouTubeMusic';
import Deezer from '../components/icons/Deezer';
import Soundcloud from '../components/icons/Soundcloud';
import CheckroomIcon from '@mui/icons-material/Checkroom';
import TikTok from '../components/icons/TikTok';
import Sweat from '../components/icons/Sweat';
import MESSY from '../assets/MESSY.gif';
import UBQ from '../assets/ubq-logo.webp';
import dayjs from 'dayjs';
import { CalendarMonth, Email, Home, Headphones, Instagram, Share } from '@mui/icons-material';
import type { Event, Tracks } from '../types/Types';

export const MEDIA_URL = 'https://ajax-wipe-n-spray.s3-ap-southeast-2.amazonaws.com';

export const tracks: Tracks = [
  {
    src: `${MEDIA_URL}/HawkTuah-dj-mix.mp3`,
    title: 'MESSY 27 July 2024 DJ mix excerpt',
    slug: 'hawk-tuah-dj-mix',
    image: `${MEDIA_URL}/Hawk-Tuah-SoundCloud-cover.jpg `,
    tag: 'Original',
    description:
      'A remix of the infamous Hawk Tuah. Fat 303 bass lines and analogue delay lines make this track fun for the whole dance floor. In my signatory style and love for DJing I leave 8 bars of silence at the end of of the track then put 8 bars of the vocal stems. This is so that DJs can set up a loop of the vocals and mix them over other tracks for seamless mixing, DJs please download and add to your sets',
    links: [
      {
        title: 'Soundcloud',
        path: 'https://soundcloud.com/dj_ajax_wipe_n_spray/hawktuah-remix-dj-mix',
        icon: <Soundcloud />
      }
    ]
  },
  {
    src: `${MEDIA_URL}/Messy-2024-07-27.mp3`,
    title: 'MESSY 27 July 2024 DJ mix excerpt',
    slug: 'messy-27-07-2024',
    image: `${MEDIA_URL}/MESSY-soundcloud-cover-27-7-2024.jpg`,
    tag: 'DJ set',
    description: 'An excerpt from the four hour MESSY DJ set on 27 July 2024 @ UBQ',
    links: [
      {
        title: 'Soundcloud',
        path: 'https://soundcloud.com/dj_ajax_wipe_n_spray/messy-27-07-2024',
        icon: <Soundcloud />
      }
    ]
  },
  {
    src: `${MEDIA_URL}/Messy-14-06-2024-excerpt.mp3`,
    title: 'MESSY 14 June 2024 DJ mix excerpt',
    slug: 'messy-14-06-2024',
    image: `${MEDIA_URL}/A2-MESSY-14-JUNE-SC-POSTER.jpg`,
    tag: 'DJ set',
    links: [
      {
        title: 'Soundcloud',
        path: 'https://soundcloud.com/dj_ajax_wipe_n_spray/messy-dj-mix-excerpt-14-june-2024',
        icon: <Soundcloud />
      }
    ]
  },
  {
    src: `${MEDIA_URL}/EatSuckSell-short-edit.mp3`,
    title: 'Eat, Suck, Sell',
    slug: 'eat-suck-sell',
    redirect: 'https://artists.landr.com/eat-suck-sell',
    description:
      'Based on a joke by John Mulaney: "It starts in a pretty rough place, but by the end of it, you have your own small business. And isn\'t that he American dream when it\'s all said and done?"',
    image: `${MEDIA_URL}/EatSuckSell-square.png`,
    tag: 'original',
    links: [
      {
        title: 'Spotify',
        path: 'https://open.spotify.com/album/76K5quBajOiVMwGpCLi1Hd',
        icon: <Spotify />
      },
      {
        title: 'Apple Music',
        path: 'https://music.apple.com/au/album/1748205870&app=music',
        icon: <AppleMusic />
      },
      {
        title: 'iTunes Store',
        path: 'https://music.apple.com/au/album/1748205870?app=itunes',
        icon: <Itunes />
      },
      {
        title: 'YouTube',
        path: 'https://music.youtube.com/playlist?list=OLAK5uy_kOfgSVO0qfCD-Zg6N2v2kurojlcqRP1gA',
        icon: <YouTubeMusic />
      },
      {
        title: 'Deezer',
        path: 'https://www.deezer.com/us/album/591739592',
        icon: <Deezer />
      },
      {
        title: 'Soundcloud',
        path: 'https://soundcloud.com/dj_ajax_wipe_n_spray/eat-suck-sell',
        icon: <Soundcloud />
      }
    ]
  },
  {
    src: `${MEDIA_URL}/FTS-radio-edit-2024-02-17.mp3`,
    title: 'Fuck That Shit [Radio Edit]',
    slug: 'fuck-that-shit',
    image: `${MEDIA_URL}/fuck-that-shit-model-shot-square.jpg`,
    tag: 'original'
  },
  {
    src: `${MEDIA_URL}/homo-02-nov-2023.mp3`,
    title: 'Homo',
    tag: 'Remix'
  },
  {
    src: `${MEDIA_URL}/Wax-on-Wax-off-Club-Mix-2023-12-30 mp3`,
    title: 'Wax On, Wax Off [Club mix]',
    tag: 'original'
  },
  {
    src: `${MEDIA_URL}/Relax-remix-ajax-wipe-n-spray.mp3`,
    title: 'Relax - Frankie Goes to Hollywood',
    tag: 'Remix'
  },
  {
    src: `${MEDIA_URL}/FTS-dj-edit-2024-02-17.mp3`,
    title: 'Fuck That Shit  [Club Mix]',
    image: `${MEDIA_URL}/fuck-that-shit-model-shot-square.jpg`,
    tag: 'original'
  },
  {
    src: `${MEDIA_URL}/French-Island-Invasion-Day-2024.mp3`,
    title: 'Invasion Day, French Island 2024 [5hr set]',
    image: `${MEDIA_URL}/AwS2-InvasionDay2024.png`,
    tag: 'DJ set'
  },
  {
    src: `${MEDIA_URL}/Lately-clubmix.mp3`,
    title: 'Lately (club mix)',
    tag: 'original'
  },
  {
    src: `${MEDIA_URL}/shanghai-shuffle.mp3`,
    title: 'Shanghai Shuffle',
    tag: 'original'
  },
  {
    src: `${MEDIA_URL}/yes-daddy-02-nov-2023.mp3`,
    title: 'Yes Daddy',
    tag: 'original'
  },
  {
    src: `${MEDIA_URL}/Virus_26_Aug_2023_set.mp3`,
    title: 'Breaching the Peace with Virus Sound System August 2023',
    tag: 'DJ set'
  },
  {
    src: `${MEDIA_URL}/Rickrolled.mp3`,
    title: 'Rickrolled (Never Gonna Give you up)',
    tag: 'Remix'
  },
  {
    src: `${MEDIA_URL}/FrenchIslandWinterDoof_June_2023_fullset_render02-001.mp3`,
    title: 'French Island Winter Doof June 2023',
    tag: 'DJ set'
  }
];

const messyTracks = tracks.filter((track) => /^messy/.test(track.slug || ''));
const messyTrackSlugs = [];
messyTracks.forEach((track) => {
  if (track.slug) {
    messyTrackSlugs.push(track.slug);
  }
});

export const events: Event[] = [
  {
    name: 'MESSY',
    // eslint-disable-next-line quotes
    description: "DJ Ajax Wipe n' Spray presents MESSY \r\n\t (dirty beats for dancy feets)",
    // create new dateStart with day.js
    startDate: dayjs('2024-08-23T20:00').locale('Sydney/Australia'),
    endDate: dayjs('2024-08-24T01:00').locale('Sydney/Australia'),
    location: {
      name: 'UBQ',
      logo: UBQ,
      addressLocality: 'Fitzroy',
      addressRegion: 'VIC',
      postalCode: '3065',
      streetAddress: '95 Smith St'
    },
    url: 'https://djajaxwipenspray.com/messy',
    urlText: '',
    image: MESSY,
    imageAlign: 'fullWidth',
    message: '',
    // eslint-disable-next-line quotes
    presenter: "DJ Ajax Wipe n' Spray",
    subheading: 'Dirty beats for dancy feets',
    tickets: {
      url: 'https://events.humanitix.com/messy-23-08-2024/tickets',
      price: 'FREE'
    },
    tracks: messyTrackSlugs
  },
  {
    name: 'MESSY',
    // eslint-disable-next-line quotes
    description: "DJ Ajax Wipe n' Spray presents MESSY \r\n\t (dirty beats for dancy feets)",
    // create new dateStart with day.js
    startDate: dayjs('2024-09-20T20:00').locale('Sydney/Australia'),
    endDate: dayjs('2024-09-21T01:00').locale('Sydney/Australia'),
    location: {
      name: 'UBQ',
      logo: UBQ,
      addressLocality: 'Fitzroy',
      addressRegion: 'VIC',
      postalCode: '3065',
      streetAddress: '95 Smith St'
    },
    url: 'https://djajaxwipenspray.com/messy',
    urlText: '',
    image: MESSY,
    imageAlign: 'fullWidth',
    message: '',
    // eslint-disable-next-line quotes
    presenter: "DJ Ajax Wipe n' Spray",
    subheading: 'Dirty beats for dancy feets',
    tickets: {
      url: '',
      price: 'FREE'
    },
    tracks: messyTrackSlugs
  },
  {
    name: 'MESSY',
    // eslint-disable-next-line quotes
    description: "DJ Ajax Wipe n' Spray presents MESSY \r\n\t (dirty beats for dancy feets)",
    // create new dateStart with day.js
    startDate: dayjs('2024-10-18T20:00').locale('Sydney/Australia'),
    endDate: dayjs('2024-10-19T01:00').locale('Sydney/Australia'),
    location: {
      name: 'UBQ',
      logo: UBQ,
      addressLocality: 'Fitzroy',
      addressRegion: 'VIC',
      postalCode: '3065',
      streetAddress: '95 Smith St'
    },
    url: 'https://djajaxwipenspray.com/messy',
    urlText: '',
    image: MESSY,
    imageAlign: 'fullWidth',
    message: '',
    // eslint-disable-next-line quotes
    presenter: "DJ Ajax Wipe n' Spray",
    subheading: 'Dirty beats for dancy feets',
    tickets: {
      url: '',
      price: 'FREE'
    },
    tracks: messyTrackSlugs
  },
  {
    name: 'MESSY',
    // eslint-disable-next-line quotes
    description: "DJ Ajax Wipe n' Spray presents MESSY \r\n\t (dirty beats for dancy feets)",
    // create new dateStart with day.js
    startDate: dayjs('2024-11-15T20:00').locale('Sydney/Australia'),
    endDate: dayjs('2024-11-16T01:00').locale('Sydney/Australia'),
    location: {
      name: 'UBQ',
      logo: UBQ,
      addressLocality: 'Fitzroy',
      addressRegion: 'VIC',
      postalCode: '3065',
      streetAddress: '95 Smith St'
    },
    url: 'https://djajaxwipenspray.com/messy',
    urlText: '',
    image: MESSY,
    imageAlign: 'fullWidth',
    message: '',
    // eslint-disable-next-line quotes
    presenter: "DJ Ajax Wipe n' Spray",
    subheading: 'Dirty beats for dancy feets',
    tickets: {
      url: '',
      price: 'FREE'
    },
    tracks: messyTrackSlugs
  }
];

export const TAGLINE = 'Beats that cut through';

const nextMessy = events[0];

export const LINKS = [
  {
    title: 'Home',
    path: '/',
    icon: <Home />,
    location: ['menu']
  },
  {
    title: 'Listen to MESSY DJ mixes',
    path: 'https://djajaxwipenspray.com/messy#listen',
    icon: <Headphones />,
    location: ['menu', 'linktree']
  },
  {
    title: `MESSY - ${dayjs(nextMessy.startDate).format('ddd D MMM')} @ ${nextMessy.location.name}`,
    path: '/messy',
    icon: <Sweat />,
    location: ['menu', 'linktree']
  },
  {
    title: 'Eat 🍩, Suck 🍆, Sell 💊',
    path: '/tracks/eat-suck-sell',
    icon: <Headphones />,
    location: ['menu', 'linktree']
  },
  {
    title: 'Linktree',
    path: '/links',
    icon: <Share />,
    location: ['menu']
  },
  {
    title: 'Tracks',
    path: '/tracks',
    icon: <Headphones />,
    location: ['menu', 'linktree']
  },
  {
    title: 'Instagram',
    path: 'https://www.instagram.com/dj_ajax_wipe_n_spray/',
    icon: <Instagram />,
    location: ['menu', 'linktree', 'follow']
  },
  {
    title: 'Spotify',
    path: 'https://open.spotify.com/artist/2mMMzk2YMvmGhvegPCuRTg?si=r07iRTsRS4C3Im08qsnOKw',
    icon: <Spotify />,
    location: ['menu', 'linktree', 'follow']
  },
  {
    title: 'Soundcloud',
    path: 'https://soundcloud.com/dj_ajax_wipe_n_spray',
    icon: <Soundcloud />,
    location: ['menu', 'linktree']
  },
  {
    title: 'TikTok',
    path: 'https://www.tiktok.com/@dj_ajax_wipe_n_spray',
    icon: <TikTok />,
    location: ['menu', 'linktree', 'follow']
  },
  {
    title: 'Website',
    path: '/',
    icon: <Home />,
    location: ['linktree']
  },
  {
    title: 'Merch',
    path: '/merch',
    icon: <CheckroomIcon />,
    location: ['menu', 'linktree']
  },
  {
    title: 'Bookings',
    path: '/contact?type=Booking',
    icon: <CalendarMonth />,
    location: ['menu', 'linktree']
  },
  {
    title: 'Contact',
    path: '/contact',
    icon: <Email />,
    location: ['menu', 'linktree']
  }
];
