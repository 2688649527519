/**
 * TikTok Icon
 * @flow
 */
import React, { type Node } from 'react';

const Sweat = (): Node => {
  return (
    <svg viewBox="0 0 128 128" xmlns="http://www.w3.org/2000/svg">
      <g fill="current-color">
        <path d="m59.2 94.33c-1.66 16.44-14.08 27.62-30.55 26.34s-23.29-15.18-22.45-28.34c.89-14.08 7.68-20.35 12-29 6-12 3.92-21.06 5.16-25.9.54-2.11 3.09-2.93 4.74-1.51 9.74 8.35 33.58 33.86 31.1 58.41z" />
        <path d="m118.97 92.21c5.69 10.66 3.03 25.35-8.09 29.66-16.67 6.46-27.67-5.54-29.67-25.54-.76-7.64-6.11-19.46-7.34-22.9-.53-1.5.66-3.02 2.23-2.83 9.23 1.15 34.37 5.7 42.87 21.61z" />
        <path d="m107.28 11.49c13.56 9.43 17.84 27.88 8.43 41.46-6.51 9.38-24.41 11.5-35.51 4.38-11.88-7.62-15.13-15.55-22-24-6.64-8.16-14.2-12.74-17.83-16.18-1.58-1.5-1.06-4.13.98-4.88 12.03-4.46 45.67-14.87 65.93-.78z" />
      </g>
      <path
        d="m53.42 94.82c-.37 1.11-.89 2.2-1.73 3-.85.81-2.07 1.29-3.2 1.02-1.66-.39-2.58-2.13-3.16-3.73-1.59-4.38-2.18-9.11-1.72-13.74.26-2.64 1.23-6.09 4.22-6.81 2-.48 3.27 1.08 4.09 2.7 2.52 4.97 3.26 12.31 1.5 17.56z"
        fill="current-color"
      />
      <path
        d="m114.92 99.24c-.02.88-.16 1.81-.76 2.45-1.06 1.15-2.97.78-4.36.07-3.15-1.6-5.55-4.45-7.09-7.63-1-2.09-2.52-5.92-.28-7.7 1.56-1.24 3.97-1.28 5.57-.21 3.99 2.68 7.05 8.25 6.92 13.02z"
        fill="current-color"
      />
      <path
        d="m106.97 24.04c-2.48 3.92-8.47 2.53-13.35-4.05-2.46-3.3-6.35-4.8-8.52-5.77-4.04-1.81-.38-4.26 1.47-4.44 5.35-.51 8.35-.11 13.2 2.31 3.82 1.92 10.04 7.47 7.2 11.95z"
        fill="current-color"
      />
    </svg>
  );
};

export default Sweat;
