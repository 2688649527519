/**
 * Home page
 * @flow
 */
import React, { type Node } from 'react';
import { Helmet } from 'react-helmet';
import Header from '../../components/header/Header';
import Curator from '../../components/curator/Curator';
import About from '../../components/about/About';
import Audio from '../../components/audio/Audio';
import Bottle from '../../components/bottle/Bottle';
import Merch from '../../components/merch/Merch';
import Footer from '../../components/footer/Footer';
import styles from './Home.module.scss';

const Home = (): Node => {
  return (
    <>
      <Helmet>
        <title>DJ Ajax Wipe n' Spray | Home</title>
        <meta name="description" content="DJ Ajax Wipe n' Spray: beats that cut through" />
      </Helmet>
      <div id="home" className={styles.HomePage}>
        <Header />
        {/* $FlowFixMe */}
        <main>
          <Audio limit={true} />
          <Curator />
          <Bottle />
          <About />
          <Merch />
        </main>
        <Footer />
      </div>
    </>
  );
};

export default Home;
