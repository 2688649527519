/**
 * Headphones Icon
 * @flow
 */
import React, { type Node } from 'react';
import styles from './Icons.module.scss';

const Headphones = (): Node => {
  return (
    <svg viewBox="0 0 7.6789141 7.6785612" version="1.1" id="svg1490" className={styles.Icon}>
      <defs id="defs1487" />
      <g id="layer1" transform="translate(-81.057979,-99.391489)">
        <g
          id="headphones"
          style={{ display: 'inline' }}
          transform="matrix(0.35277777,0,0,-0.35277777,28.14664,148.97578)"
        >
          <g id="g411" transform="translate(150.4849,129.6709)" style={{ display: 'inline' }}>
            <path
              d="m 0,0 c 0,5.734 4.649,10.383 10.384,10.383 5.734,0 10.383,-4.649 10.383,-10.383 0,-5.734 -4.649,-10.383 -10.383,-10.383 C 4.649,-10.383 0,-5.734 0,0"
              style={{
                fill: '#000000',
                fillOpacity: 1,
                fillRule: 'nonzero',
                stroke: 'none'
              }}
              id="path413"
            />
          </g>
          <g id="g415" transform="translate(150.4849,129.6709)">
            <path
              d="m 0,0 c 0,5.734 4.649,10.383 10.384,10.383 5.734,0 10.383,-4.649 10.383,-10.383 0,-5.734 -4.649,-10.383 -10.383,-10.383 C 4.649,-10.383 0,-5.734 0,0 Z"
              style={{
                fill: 'none',
                stroke: '#ffffff',
                strokeWidth: 1,
                strokeLinecap: 'butt',
                strokeLinejoin: 'miter',
                strokeMiterlimit: 10,
                strokeDasharray: 'none',
                strokeOpacity: 1
              }}
              id="path417"
            />
          </g>
          <g id="g443" transform="translate(166.6118,129.6172)">
            <path
              d="m 0,0 v -3.42 c 0,-0.269 -0.22,-0.5 -0.5,-0.5 -0.271,0 -0.5,0.231 -0.5,0.5 v 3.471 c 0.01,0.069 0.21,2.34 -1.141,3.83 C -2.94,4.761 -4.16,5.21 -5.75,5.21 -7.34,5.21 -8.561,4.761 -9.38,3.87 -10.74,2.37 -10.55,0.12 -10.55,0.101 V -3.42 c 0,-0.269 -0.221,-0.5 -0.5,-0.5 -0.2,0 -0.945,0.642 -1.15,1.07 -0.034,0.072 -0.364,0.793 0,1.55 0.195,0.406 0.499,0.633 0.65,0.731 0,0 -0.23,3.29 1.43,5.109 1.019,1.11 2.49,1.67 4.37,1.67 1.89,0 3.359,-0.56 4.359,-1.67 C 0.25,2.721 0.01,0.11 0,0"
              style={{
                fill: '#ffffff',
                fillOpacity: 1,
                fillRule: 'nonzero',
                stroke: 'none'
              }}
              id="path445"
            />
          </g>
          <g id="g447" transform="translate(164.1226,128.8193)">
            <path
              d="M 0,0 V -2.941"
              style={{
                fill: 'none',
                stroke: '#ffffff',
                strokeWidth: 2.126,
                strokeLinecap: 'round',
                strokeLinejoin: 'miter',
                strokeMiterlimit: 10,
                strokeDasharray: 'none',
                strokeOpacity: 1
              }}
              id="path449"
            />
          </g>
          <g id="g451" transform="translate(157.5288,128.7773)">
            <path
              d="M 0,0 V -2.942"
              style={{
                fill: 'none',
                stroke: '#ffffff',
                strokeWidth: 2.126,
                strokeLinecap: 'round',
                strokeLinejoin: 'miter',
                strokeMiterlimit: 10,
                strokeDasharray: 'none',
                strokeOpacity: 1
              }}
              id="path453"
            />
          </g>
          <g id="g455" transform="translate(155.0679,129.6172)" style={{ display: 'inline' }}>
            <path
              d="m 0,0 v -3.42 c 0,-0.269 0.221,-0.5 0.5,-0.5 0.271,0 0.5,0.231 0.5,0.5 V 0.051 C 0.99,0.12 0.79,2.391 2.141,3.881 2.94,4.761 4.16,5.21 5.75,5.21 7.34,5.21 8.561,4.761 9.38,3.87 10.74,2.37 10.55,0.12 10.55,0.101 V -3.42 c 0,-0.269 0.221,-0.5 0.5,-0.5 0.2,0 0.945,0.642 1.15,1.07 0.034,0.072 0.364,0.793 0,1.55 -0.195,0.406 -0.499,0.633 -0.65,0.731 0,0 0.23,3.29 -1.43,5.109 C 9.101,5.65 7.63,6.21 5.75,6.21 3.86,6.21 2.391,5.65 1.391,4.54 -0.25,2.721 -0.01,0.11 0,0"
              style={{
                fill: '#ffffff',
                fillOpacity: 1,
                fillRule: 'nonzero',
                stroke: 'none'
              }}
              id="path457"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Headphones;
