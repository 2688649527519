/* eslint-disable prettier/prettier */
/* eslint-disable quotes */
import { MEDIA_URL } from '../../data/Data';

export const MERCH = [
  {
    title: 'Fuck That Shit Crop Top',
    description: "Available in x-small, small, medium, large and x-large in pale pink. Adult cotton women's sizes",
    price: 45,
    image: `${MEDIA_URL}/fuck-that-shit-model-shot-square.jpg`,
    link: 'https://buy.stripe.com/3cs5nXaGi9ardXy4gi'
  },
  {
    title: 'Ajax Wipe n\' Spray T-shirts',
    description:
      'Available in x-small, small, medium, large and x-large in yellow, orange or green. Adult cotton unisex',
    price: 45,
    image: `${MEDIA_URL}/dj-ajax-spray-n-wipe-bottle-t-shirt.jpg`,
    link: 'https://buy.stripe.com/4gwg2B9Ce2M35r2289'
  },
  {
    title: 'Ajax Wipe n\' Spray Text Only T-shirts',
    description:
      'Available in small, medium, large and x-large in blue, green, yellow and orange.  Adult cotton unisex',
    price: 45,
    image: `${MEDIA_URL}/dj-ajax-spray-wipe-text-only-t-shirt.jpg`,
    link: 'https://buy.stripe.com/cN2cQp01E4Ubg5G3cf'
  }
];
