/**
 * Events / gigs
 * @flow
 */
import React, { type Node } from 'react';
import Event from '../event/Event';
import { events } from '../../data/Data';
import styles from './Events.module.scss';

type Props = {
  shift: boolean
};

const Events = (props: Props): Node => {
  const { shift } = props;

  return (
    <div className={styles.Events}>
      <h2>The MESSY residency continues</h2>
      {events.length === 0 ? (
        <div className={styles.NoEvents}>No gigs yet.</div>
      ) : (
        <div className={styles.EventsList}>
          {events.map((event, index) => {
            return index === 0 && shift ? null : <Event event={event} key={index} />;
          })}
        </div>
      )}
    </div>
  );
};

Events.defaultProps = {
  shift: false
};

export default Events;
