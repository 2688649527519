/**
 * Messy page
 * @flow
 */
import type { Event, Track } from '../types/Types';
import dayjs from 'dayjs';

export const createTrackJsonLd = (track: Track | void, duration?: string): string => {
  if (!track) {
    return '';
  }
  const { src, description, title, image } = track;
  const trackJson = {
    track: {
      '@type': 'MusicRecording',
      audio: src,
      // eslint-disable-next-line quotes
      byArtist: "DJ Ajax Wipe n' Spray",
      abstract: description,
      name: title,
      image,
      genre: 'Tech House'
    }
  };
  if (duration) {
    // $FlowFixMe
    trackJson.track.duration = duration;
  }
  return JSON.stringify(trackJson, null, 2);
};

export const createEventJsonLd = (event: Event): string => {
  const {
    name,
    description,
    image,
    startDate,
    endDate,
    location,
    presenter,
    tickets: { url, price }
  } = event;

  const eventJson = {
    '@context': 'https://schema.org',
    '@type': 'MusicGroup',
    event: {
      '@type': 'Event',
      location: {
        '@type': 'Place',
        name: location.name,
        address: {
          '@type': 'PostalAddress',
          streetAddress: location.streetAddress,
          addressLocality: location.addressLocality,
          addressRegion: location.addressRegion,
          postalCode: location.postalCode
        }
      },
      offers: {
        '@type': 'Offer',
        price,
        priceCurrency: 'AUD',
        url
      },
      name: `${presenter} presents ${name}`,
      description,
      image,
      startDate: dayjs(startDate).format('YYYY-MM-DDTHH:mm'),
      endDate: dayjs(endDate).format('YYYY-MM-DDTHH:mm'),
      eventStatus: 'https://schema.org/EventScheduled',
      performer: {
        '@type': 'MusicGroup',
        name: presenter
      },
      organizer: {
        '@type': 'MusicGroup',
        name: presenter,
        url: 'https://djajaxwipenspray.com/messy'
      },
      eventAttendanceMode: 'https://schema.org/OfflineEventAttendanceMode'
    },
    name: presenter,
    tracks: event.tracks?.map((track) => createTrackJsonLd(track))
  };
  return JSON.stringify(eventJson, null, 2);
};
