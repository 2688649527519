/**
 * YouTubeMusic Icon
 * @flow
 */
import React, { type Node } from 'react';
import styles from './Icons.module.scss';

export const YouTubeMusic = (): Node => {
  return (
    <svg fill="currentColor" viewBox="0 0 76 76" className={styles.YouTubeMusic}>
      <path
        fillOpacity="1"
        strokeWidth="0.2"
        strokeLinejoin="round"
        d="M 25.3333,23.75C 25.3333,23.75 31.7986,22.9583 38.066,22.9583C 44.3333,22.9583 50.6667,23.75 50.6666,23.75C 55.0389,23.75 58.5833,27.2944 58.5833,31.6667L 58.5833,44.3333C 58.5833,48.7056 53.8333,52.25 50.6666,52.25C 50.6666,52.25 44.2013,53.0416 37.934,53.0416C 31.6666,53.0416 25.3333,52.25 25.3333,52.25C 20.9611,52.25 17.4167,48.7056 17.4167,44.3333L 17.4167,31.6667C 17.4167,27.2944 22.1667,23.75 25.3333,23.75 Z M 34.8333,28.5L 34.8333,47.5L 44.3333,38L 34.8333,28.5 Z "
      />
    </svg>
  );
};

export default YouTubeMusic;
