/**
 * Contact page
 * @flow
 */
import React, { useRef, type Node } from 'react';
import { Helmet } from 'react-helmet';
import Header from '../../components/header/Header';
import ContactForm from '../../components/contact-form/ContactForm';
import Footer from '../../components/footer/Footer';
import styles from './Contact.module.scss';

const ContactPage = (): Node => {
  const headerRef = useRef(null);

  return (
    <>
      <Helmet>
        <title>DJ Ajax Wipe n' Spray | Contact</title>
        <meta name="description" content="Contact &amp; book DJ Ajax Wipe n' Spray" />
      </Helmet>
      <div id="contact" className={styles.ContactPage}>
        <Header ref={headerRef} />
        {/* $FlowFixMe */}
        <main>
          <h1>Contact</h1>
          <ContactForm />
        </main>
        <Footer />
      </div>
    </>
  );
};

export default ContactPage;
