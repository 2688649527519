/**
 * Audio
 * @flow
 */
import React, { useEffect, useState, type Node } from 'react';
import Track from '../track/Track';
import { Link } from 'react-router-dom';
import { tracks } from '../../data/Data';
import type { Track as TrackType } from '../../types/Types';
import styles from './Audio.module.scss';

type Props = {
  limit: boolean,
  slugs?: string[] // slugs of the tracks to filter for
};

const Audio = (props: Props): Node => {
  const { limit, slugs } = props;
  const [playing, setPlaying] = useState<null | TrackType>(null);
  const [songs, setSongs] = useState<TrackType[]>([]);

  useEffect(() => {
    const currentTracks = [];
    if (slugs !== undefined) {
      tracks.forEach((track) => {
        if (slugs && slugs.includes(track.slug)) {
          currentTracks.push(track);
        }
      });
      setSongs(currentTracks);
    } else {
      setSongs(tracks);
    }
  }, [tracks, slugs]);

  const onPlay = (track: TrackType) => {
    setPlaying(track);
    return null;
  };

  const onEnded = () => {
    // find the song that just ended
    let next = null;
    let nextIndex = 0;
    for (let i = 0; i < songs.length; i++) {
      if (songs[i].src === playing?.src) {
        nextIndex = i + 1;
        next = songs[nextIndex];
        break;
      }
    }
    if (next === null) {
      next = songs[0];
      nextIndex = 0;
    }
    setPlaying(next);
    return null;
  };

  return (
    <div className={styles.AudioContainer}>
      <ul>
        {songs.map((track, index) => {
          if (limit && index >= 10) {
            return null;
          }
          return <Track playing={playing} index={index} key={index} track={track} onPlay={onPlay} onEnded={onEnded} />;
        })}
      </ul>
      {limit && songs.length > 10 ? (
        <Link to="/tracks" className={styles.TracksLink}>
          View all tracks
        </Link>
      ) : null}
    </div>
  );
};

Audio.defaultProps = {
  limit: false
};

export default Audio;
