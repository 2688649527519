/**
 * About
 * @flow
 */
import React, { type Node } from 'react';
import { Link } from 'react-router-dom';
import { CalendarMonth, Email, Instagram } from '@mui/icons-material';
import Spotify from '../icons/Spotify';
import Soundcloud from '../../components/icons/Soundcloud';
import TikTok from '../../components/icons/TikTok';
import styles from './About.module.scss';

const About = (): Node => {
  const scrollTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className={styles.About}>
      <div className={styles.Text}>
        <h2>About</h2>
        <p>DJ Ajax Wipe n’ Spray is the camp, queer brand for party people.</p>
        <ul>
          <li>Your friendly Gaybourhood DJ</li>
          <li>Beats that cut through</li>
          <li>Let’s dance!</li>
        </ul>
        <h3>The backstory</h3>
        <p>
          Ajax Wipe n' Spray got his name "Ajax" in the 3rd grade, and some years later, in a fateful meeting in the
          toilets at Poof Doof,, he gained his DJ name: DJ Ajax Wipe n' Spray…{' '}
        </p>
        <p>And the legend was born.</p>
        <p>
          A master of producing rich tech house tunes, he is a DJ to experience. He has an intimate feel for groove.
          Tech house and techno are the tools of his trade. One thing is for sure, you're gonna get your booty moving on
          the dance floor.
        </p>
        <p>
          Ajax began DJing and producing tunes in May 2023. He has been playing with Virus Sound System for underground
          warehouse parties in Melbourne, as well as selected bush doofs.
        </p>
      </div>
      <ul className={styles.Links}>
        <li>
          <a href="https://www.tiktok.com/@dj_ajax_wipe_n_spray" target="_blank" rel="noreferrer">
            <TikTok /> <span className={styles.LinkText}>TikTok</span>
          </a>
        </li>
        <li>
          <a href="https://www.instagram.com/dj_ajax_wipe_n_spray/" target="_blank" rel="noreferrer">
            <Instagram /> <span className={styles.LinkText}>Instagram</span>
          </a>
        </li>
        <li>
          <a href="https://soundcloud.com/dj_ajax_wipe_n_spray" target="_blank" rel="noreferrer">
            <Soundcloud /> <span className={styles.LinkText}>Soundcloud</span>
          </a>
        </li>
        <li>
          <a
            href="https://open.spotify.com/playlist/5tbPnR0drVKURNZaorZciV?si=866b5e0d0f0044d4"
            target="_blank"
            rel="noreferrer"
          >
            <Spotify /> <span className={styles.LinkText}>Spotify</span>
          </a>
        </li>
        <li>
          <Link to="/contact?type=Booking" onClick={scrollTop}>
            <CalendarMonth /> <span className={styles.LinkText}>Bookings</span>
          </Link>
        </li>
        <li>
          <Link to="/contact?type=General" onClick={scrollTop}>
            <Email /> <span className={styles.LinkText}>Contact</span>
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default About;
