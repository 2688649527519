/**
 * Tracks page
 * @flow
 */
import React, { type Node } from 'react';
import { Helmet } from 'react-helmet';
import Header from '../../components/header/Header';
import Audio from '../../components/audio/Audio';
import Footer from '../../components/footer/Footer';
import styles from './Tracks.module.scss';

const TracksPage = (): Node => {
  return (
    <>
      <Helmet>
        <title>DJ Ajax Wipe n' Spray | Tracks</title>
        <meta name="description" content="Originals, remixes and DJ sets by DJ Ajax Wipe n' Spray" />
      </Helmet>

      <div className={styles.Tracks}>
        <Header />
        {/* $FlowFixMe */}
        <main>
          <div className={styles.Blurb}>
            <h1>Tracks</h1>
            <p>DJ Ajax Wipe n' Spray originals track, bootleg remixes &amp; DJ sets</p>
          </div>
          <Audio />
        </main>
        <Footer />
      </div>
    </>
  );
};

export default TracksPage;
