import { createBrowserRouter } from 'react-router-dom';
import HomePage from '../pages/home/Home';
import ContactPage from '../pages/contact/Contact';
import TracksPage from '../pages/tracks/Tracks';
import MessyPage from '../pages/messy/Messy';
import SingleTrackPage from '../pages/tracks/single-track/SingleTrack';
import Merch from '../pages/merch/Merch';
import LinktreePage from '../pages/linktree/Linktree';

export const router = createBrowserRouter([
  {
    path: '/',
    exact: true,
    element: <HomePage />
  },
  {
    path: '/links',
    exact: true,
    element: <LinktreePage />
  },
  {
    path: '/messy',
    exact: true,
    element: <MessyPage />
  },
  {
    path: '/merch',
    exact: true,
    element: <Merch />
  },
  {
    path: '/tracks',
    exact: true,
    element: <TracksPage />
  },
  {
    path: '/tracks/:id',
    exact: true,
    element: <SingleTrackPage />
  },
  {
    path: '/contact',
    exact: true,
    element: <ContactPage />
  },
  {
    path: '/*',
    exact: false,
    element: <HomePage />
  }
]);
