import React, { useEffect } from 'react';

const Curator = () => {
  const key = 'd1579d37-c10d-4842-992e-63c3e0d9303b';

  useEffect(() => {
    const loadScript = (src, libraryId) => {
      return new Promise((resolve) => {
        const script = document.createElement('script');
        script.src = src;
        script.id = libraryId;
        document.body.appendChild(script);

        script.onload = () => {
          // add a small timeout to give it time to load
          setTimeout(() => {
            resolve(true);
          }, 100);
        };
      });
    };

    loadScript(`https://cdn.curator.io/published/${key}.js`);
  }, [key]);

  return <div data-crt-feed-id={key} />;
};

export default Curator;
