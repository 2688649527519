/**
 * Menu component
 * @flow
 */
import React, { type Node } from 'react';
import { Link } from 'react-router-dom';
import { ListItem, ListItemButton, ListItemText } from '@mui/material';
import { LINKS } from '../../data/Data';
import styles from './Menu.module.scss';

type Props = {
  setOpen: (open: boolean) => any
};

const TheActualMenu = (props: Props): Node => {
  const { setOpen } = props;

  const close = () => {
    setOpen(false);
  };

  return LINKS.map((link, index) => {
    const { icon, location, title, path } = link;

    if (location.indexOf('menu') === -1) {
      return null;
    }

    return (
      <ListItem key={index} divider={true}>
        <ListItemButton>
          <ListItemText>
            {/^http/.test(link.path) ? (
              <a href={path} target="_blank" onClick={close} rel="noreferrer">
                {icon} <span className={styles.LinkText}>{title}</span>
              </a>
            ) : (
              <Link to={path} onClick={close}>
                {icon} <span className={styles.Text}>{title}</span>
              </Link>
            )}
          </ListItemText>
        </ListItemButton>
      </ListItem>
    );
  });
};

TheActualMenu.defaultProps = {
  setOpen: () => {}
};

export default TheActualMenu;
