/**
 * ContactForm component
 * @flow
 */
import React, { useEffect, useState, type Node } from 'react';
import { Alert, Button, CircularProgress, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { Send } from '@mui/icons-material';
import { isEmail, isMobilePhone } from 'validator';
import styles from './ContactForm.module.scss';

const ContactForm = (): Node => {
  const typeValues = ['General', 'Booking', 'Remix'];
  const [values, setValues] = useState({
    type: {
      label: 'Enquiry type',
      value: 'General',
      error: false,
      required: true
    },
    name: {
      label: 'Name',
      value: '',
      error: false,
      errorMessage: 'Please enter your name',
      required: true
    },
    email: {
      label: 'Email',
      value: '',
      error: false,
      errorMessage: 'Email address is not valid',
      required: true
    },
    phone: {
      label: 'Phone',
      value: '',
      error: false,
      errorMessage: 'Phone number is not valid',
      required: false
    },
    message: {
      label: 'Message',
      value: '',
      error: false,
      errorMessage: 'Please enter your message',
      required: true
    }
  });
  const [status, setStatus] = useState('idle');

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const type = urlParams.get('type');

    if (type) {
      setValues({
        ...values,
        type: {
          ...values.type,
          value: type
        }
      });
    }
  }, []);

  /**
   * Set form values
   */
  const setFormValues = (value: string, arg: string) => {
    let error = false;
    let { required } = values[arg];

    switch (true) {
      case arg === 'type' && value === typeValues[0]:
      case arg === 'type' && value === typeValues[3]:
        setValues({
          ...values,
          phone: {
            ...values.phone,
            required: false
          },
          type: {
            ...values.type,
            value
          }
        });
        return;

      case arg === 'type' && value === typeValues[1]:
      case arg === 'type' && value === typeValues[2]:
        setValues({
          ...values,
          phone: {
            ...values.phone,
            required: true
          },
          type: {
            ...values.type,
            value
          }
        });
        return;

      case arg === 'email':
        error = isEmail(value) && values[arg].required ? false : true;
        break;

      case arg === 'phone':
        error = isMobilePhone(value) && values[arg].required ? false : true;
        break;

      default:
        error = value.length === 0 && values[arg].required ? true : false;
        break;
    }

    setValues({
      ...values,
      // $FlowFixMe
      [arg]: {
        ...values[arg],
        error,
        required,
        value
      }
    });
  };

  /**
   * Validate form
   */
  const validateForm = () => {
    const newValues = { ...values };

    for (let arg in newValues) {
      let error = false;
      const { value, required } = newValues[arg];

      if (arg === 'email') {
        error = isEmail(value) ? false : true;
      } else {
        error = value.length === 0 && required ? true : false;
      }

      newValues[arg].error = error;
    }

    setValues(newValues);
  };

  const hasErrors = () => {
    for (let arg in values) {
      if (values[arg].error) {
        return true;
      }
    }
    return false;
  };

  /**
   * handle form submit
   */
  const handleSubmit = (e: SyntheticMouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    validateForm();
    const errors = hasErrors();

    if (errors) {
      return;
    }

    setStatus('sending');

    sendToSlack();
  };

  /**
   * Send to slack
   */
  const sendToSlack = () => {
    const slackUrl = 'https://hooks.slack.com/services/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';
    let text = '';

    for (let arg in values) {
      const { label, value, required } = values[arg];

      if (arg === 'type') {
        text += `*${value.toUpperCase()}*\n`;
      } else if (required) {
        text += `*${label}:* ${value}\n`;
      }
    }

    const slackMessage = { text };

    fetch(slackUrl, {
      method: 'POST',
      body: JSON.stringify(slackMessage)
    })
      .then((response) => {
        if (response.ok) {
          setStatus('success');
          return;
        } else {
          throw new Error('Something went wrong');
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /**
   * Render form
   */
  const renderForm = () => {
    let fields = [];
    let fieldErrors = [];

    for (let arg in values) {
      const { error, errorMessage, label, value, required } = values[arg];

      if (arg === 'type') {
        fields.push(
          <FormControl key={arg} fullWidth={true}>
            <InputLabel
              classes={{
                root: {
                  color: 'rgb(0, 0, 0, 0.5)'
                }
              }}
            >
              {label}
            </InputLabel>
            <Select
              id="type"
              label="Contact Type"
              variant="outlined"
              fullWidth={true}
              required={required}
              error={error}
              value={value}
              onChange={(e) => setFormValues(e.target.value, 'type')}
              className={styles.Input}
            >
              {typeValues.map((type, index) => (
                <MenuItem key={index} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      } else if (required) {
        const inputProps =
          arg === 'phone' ? { inputMode: 'numeric' } : arg === 'email' ? { inputMode: 'email' } : { inputMode: 'text' };
        const inputType = arg === 'email' ? 'email' : arg === 'phone' ? 'phone' : 'text';

        fields.push(
          <FormControl key={arg} fullWidth={true}>
            <TextField
              id={arg}
              label={label}
              name={arg}
              variant="outlined"
              fullWidth={true}
              type={inputType}
              inputProps={inputProps}
              required={required}
              multiline={arg === 'message' ? true : false}
              rows={arg === 'message' ? 4 : 1}
              error={error}
              value={value}
              onChange={(e) => setFormValues(e.target.value, arg)}
              onBlur={(e) => setFormValues(e.target.value, arg)}
              className={styles.Input}
            />
          </FormControl>
        );

        if (error) {
          fieldErrors.push(<Alert severity="error">{errorMessage}</Alert>);
        }
      }
    }
    fields.push(
      <Button
        key="submit"
        sx={{ bgcolor: '#ed1e79' }}
        variant="contained"
        endIcon={<Send />}
        onClick={handleSubmit}
        className={styles.Button}
      >
        Send
      </Button>
    );

    switch (status) {
      case 'success':
        return <Alert severity="success">Thanks for your message!</Alert>;

      case 'sending':
        return <CircularProgress sx={{ color: '#ed1e79' }} />;

      default:
        return (
          <>
            <form>{fields}</form>
            {!hasErrors() ? null : <div className={styles.Errors}>{fieldErrors}</div>}
          </>
        );
    }
  };

  return (
    <div id="contact" className={styles.ContactForm}>
      {renderForm()}
    </div>
  );
};

export default ContactForm;
