import styles from './CalIcons.module.scss';
export const GoogleCalIcon =
  'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciICB2aWV3Qm94PSIwIDAgNDggNDgiIHdpZHRoPSI0OHB4IiBoZWlnaHQ9IjQ4cHgiPjxyZWN0IHdpZHRoPSIyMiIgaGVpZ2h0PSIyMiIgeD0iMTMiIHk9IjEzIiBmaWxsPSIjZmZmIi8+PHBvbHlnb24gZmlsbD0iIzFlODhlNSIgcG9pbnRzPSIyNS42OCwyMC45MiAyNi42ODgsMjIuMzYgMjguMjcyLDIxLjIwOCAyOC4yNzIsMjkuNTYgMzAsMjkuNTYgMzAsMTguNjE2IDI4LjU2LDE4LjYxNiIvPjxwYXRoIGZpbGw9IiMxZTg4ZTUiIGQ9Ik0yMi45NDMsMjMuNzQ1YzAuNjI1LTAuNTc0LDEuMDEzLTEuMzcsMS4wMTMtMi4yNDljMC0xLjc0Ny0xLjUzMy0zLjE2OC0zLjQxNy0zLjE2OCBjLTEuNjAyLDAtMi45NzIsMS4wMDktMy4zMywyLjQ1M2wxLjY1NywwLjQyMWMwLjE2NS0wLjY2NCwwLjg2OC0xLjE0NiwxLjY3My0xLjE0NmMwLjk0MiwwLDEuNzA5LDAuNjQ2LDEuNzA5LDEuNDQgYzAsMC43OTQtMC43NjcsMS40NC0xLjcwOSwxLjQ0aC0wLjk5N3YxLjcyOGgwLjk5N2MxLjA4MSwwLDEuOTkzLDAuNzUxLDEuOTkzLDEuNjRjMCwwLjkwNC0wLjg2NiwxLjY0LTEuOTMxLDEuNjQgYy0wLjk2MiwwLTEuNzg0LTAuNjEtMS45MTQtMS40MThMMTcsMjYuODAyYzAuMjYyLDEuNjM2LDEuODEsMi44NywzLjYsMi44N2MyLjAwNywwLDMuNjQtMS41MTEsMy42NC0zLjM2OCBDMjQuMjQsMjUuMjgxLDIzLjczNiwyNC4zNjMsMjIuOTQzLDIzLjc0NXoiLz48cG9seWdvbiBmaWxsPSIjZmJjMDJkIiBwb2ludHM9IjM0LDQyIDE0LDQyIDEzLDM4IDE0LDM0IDM0LDM0IDM1LDM4Ii8+PHBvbHlnb24gZmlsbD0iIzRjYWY1MCIgcG9pbnRzPSIzOCwzNSA0MiwzNCA0MiwxNCAzOCwxMyAzNCwxNCAzNCwzNCIvPjxwYXRoIGZpbGw9IiMxZTg4ZTUiIGQ9Ik0zNCwxNGwxLTRsLTEtNEg5QzcuMzQzLDYsNiw3LjM0Myw2LDl2MjVsNCwxbDQtMVYxNEgzNHoiLz48cG9seWdvbiBmaWxsPSIjZTUzOTM1IiBwb2ludHM9IjM0LDM0IDM0LDQyIDQyLDM0Ii8+PHBhdGggZmlsbD0iIzE1NjVjMCIgZD0iTTM5LDZoLTV2OGg4VjlDNDIsNy4zNDMsNDAuNjU3LDYsMzksNnoiLz48cGF0aCBmaWxsPSIjMTU2NWMwIiBkPSJNOSw0Mmg1di04SDZ2NUM2LDQwLjY1Nyw3LjM0Myw0Miw5LDQyeiIvPjwvc3ZnPg==';

export const OutlookIcon =
  'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciICB2aWV3Qm94PSIwIDAgNDggNDgiIHdpZHRoPSI0OHB4IiBoZWlnaHQ9IjQ4cHgiPjxwYXRoIGZpbGw9IiMwM0E5RjQiIGQ9Ik0yMSwzMWMwLDEuMTA0LDAuODk2LDIsMiwyaDE3YzEuMTA0LDAsMi0wLjg5NiwyLTJWMTZjMC0xLjEwNC0wLjg5Ni0yLTItMkgyM2MtMS4xMDQsMC0yLDAuODk2LTIsMlYzMXoiLz48cGF0aCBmaWxsPSIjQjNFNUZDIiBkPSJNNDIsMTYuOTc1VjE2YzAtMC40MjgtMC4xMzctMC44MjMtMC4zNjctMS4xNDhsLTExLjI2NCw2LjkzMmwtNy41NDItNC42NTZMMjIuMTI1LDE5bDguNDU5LDVMNDIsMTYuOTc1eiIvPjxwYXRoIGZpbGw9IiMwMjc3QkQiIGQ9Ik0yNyA0MS40Nkw2IDM3LjQ2IDYgOS40NiAyNyA1LjQ2eiIvPjxwYXRoIGZpbGw9IiNGRkYiIGQ9Ik0yMS4yMTYsMTguMzExYy0xLjA5OC0xLjI3NS0yLjU0Ni0xLjkxMy00LjMyOC0xLjkxM2MtMS44OTIsMC0zLjQwOCwwLjY2OS00LjU1NCwyLjAwM2MtMS4xNDQsMS4zMzctMS43MTksMy4wODgtMS43MTksNS4yNDZjMCwyLjA0NSwwLjU2NCwzLjcxNCwxLjY5LDQuOTg2YzEuMTI2LDEuMjczLDIuNTkyLDEuOTEsNC4zNzgsMS45MWMxLjg0LDAsMy4zMzEtMC42NTIsNC40NzQtMS45NzVjMS4xNDMtMS4zMTMsMS43MTItMy4wNDMsMS43MTItNS4xOTlDMjIuODY5LDIxLjI4MSwyMi4zMTgsMTkuNTk1LDIxLjIxNiwxOC4zMTF6IE0xOS4wNDksMjYuNzM1Yy0wLjU2OCwwLjc2OS0xLjMzOSwxLjE1Mi0yLjMxMywxLjE1MmMtMC45MzksMC0xLjY5OS0wLjM5NC0yLjI4NS0xLjE4N2MtMC41ODEtMC43ODUtMC44Ny0xLjg2MS0wLjg3LTMuMjExYzAtMS4zMzYsMC4yODktMi40MTQsMC44Ny0zLjIyNWMwLjU4Ni0wLjgxLDEuMzY4LTEuMjExLDIuMzU1LTEuMjExYzAuOTYyLDAsMS43MTgsMC4zOTMsMi4yNjcsMS4xNzhjMC41NTUsMC43OTUsMC44MzMsMS44OTUsMC44MzMsMy4zMUMxOS45MDcsMjQuOTA2LDE5LjYxOCwyNS45NjgsMTkuMDQ5LDI2LjczNXoiLz48L3N2Zz4=';

export const ICalIcon = (props) => {
  const { startDate } = props.event;
  const date = startDate.format('DD');
  const month = startDate.format('MMM');

  return (
    <div className={styles.ICalIconContainer}>
      <div className={styles.ICalIcon}>
        <div className={styles.top}>{month}</div>
        <div className={styles.date}>{date}</div>
      </div>
    </div>
  );
};
