/**
 * Track page
 * @flow
 */
import React, { type Node, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import Header from '../../../components/header/Header';
import Track from '../../../components/track/Track';
import TrackOutboundLinks from '../../../components/track/TrackOutboundLinks';
import Footer from '../../../components/footer/Footer';
import { tracks } from '../../../data/Data';
import styles from '../Tracks.module.scss';

const SingleTrackPage = (): Node => {
  const { id } = useParams();
  const track = tracks.filter((track) => track.slug === id);
  const [showTrack, setShowTrack] = useState(false);

  useEffect(() => {
    if (!track || !track[0]) {
      return;
    }

    // find out if one of the tracks links has `title` === 'Spotify'
    const spotify = track[0].links?.find((link) => link.title === 'Spotify');

    if (spotify) {
      setShowTrack(true);
    }
  }, [track, setShowTrack]);

  if (!track || !track[0]) {
    return null;
  }
  const { title, description, image } = track[0];
  console.warn(('showTrack', showTrack));
  return (
    <>
      <Helmet>
        <title>{title} | DJ Ajax Wipe n' Spray</title>
        <meta name="description" content={description ? description : `${title} by DJ Ajax Wipe n' Spray`} />
      </Helmet>

      <div className={styles.SingleTrackPage}>
        <Header />
        {/* $FlowFixMe */}
        <main>
          <div className={styles.Blurb}>
            {image ? <img src={image} alt={title} /> : null}
            <h1>{title}</h1>
          </div>
          <TrackOutboundLinks track={track[0]} />
          {description ? <p>{description}</p> : null}
          <ul className={styles.Track}>
            <Track track={track[0]} single={true} hide={showTrack} />
          </ul>
        </main>
        <Footer />
      </div>
    </>
  );
};

export default SingleTrackPage;
