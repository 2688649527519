/**
 * Events / gigs
 * @flow
 */
import React, { type Node } from 'react';
import dayjs from 'dayjs';
import { CalendarMonth, Room, Link, Info } from '@mui/icons-material';
import type { Event as EventType } from '../../types/Types';
import styles from './Event.module.scss';

type Props = {
  event: EventType
};

const Event = (props: Props): Node => {
  const { event } = props;
  const { startDate, imageAlign, location, subheading, url, tickets } = event;

  return (
    <div className={`${styles.Event} ${imageAlign === 'fullWidth' ? styles.EventImageFullWidth : ''}`}>
      <div className={styles.EventDetails}>
        <div className={styles.EventDate}>
          <CalendarMonth /> {dayjs(startDate).format('h:mma dddd D MMM YYYY')}
        </div>
        <div className={styles.EventLocation}>
          <Room /> {location.name} {location.streetAddress}, {location.addressLocality}, {location.addressRegion}{' '}
          {location.postalCode}
        </div>
        {tickets.url && tickets.url !== '' && /^http/.test(tickets.url) ? (
          <div className={styles.EventUrl}>
            <a href={url} target="_blank" rel="noopener noreferrer">
              <Link /> Tickets {tickets.price ? `from ${tickets.price}` : ''}
            </a>
          </div>
        ) : null}
        <div className={styles.EventMessage}>
          <Info /> {subheading}
        </div>
      </div>
    </div>
  );
};

export default Event;
