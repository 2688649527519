/**
 * Add to Calendar
 */
import { GoogleCalIcon, OutlookIcon, ICalIcon } from '../icons/CalIcons';
import { events } from '../../data/Data';
import { v4 } from 'uuid';
import dayjs from 'dayjs';
import styles from './AddToCalendar.module.scss';

const formatCalLink = (event, target = 'google') => {
  // write a function to format the calendar link for Google Calendar or Outlook
  const { name, description, startDate, endDate, location } = event;
  const locationString = `${location.name}, ${location.streetAddress}, ${location.addressLocality}, ${location.addressRegion} ${location.postalCode}`;
  const search = new URLSearchParams();
  let link = '';

  switch (target) {
    case 'google': {
      // format startDate Date object into a string with Format  YYYYMMDDTHHmmssZ using dayjs
      search.set('dates', `${startDate.format('YYYYMMDDTHHmmssZ')}/${endDate.format('YYYYMMDDTHHmmssZ')}`);
      search.set('action', 'TEMPLATE');
      search.set('details', description);
      search.set('location', locationString);
      search.set('text', name);
      search.set('ctz', 'Australia/Sydney');
      link = `https://calendar.google.com/calendar/render?${search.toString()}`;
      break;
    }

    case 'outlook': {
      // format startDate Date object into a string with Format  YYYY-MM-DDTHH:MM:00+00:00 using dayjs
      const startDateFormatted = dayjs(startDate).format('YYYY-MM-DDTHH:mm:00+00:00');
      const endDateFormatted = dayjs(endDate).format('YYYY-MM-DDTHH:mm:00+00:00');

      search.set('allday', 'false');
      search.set('body', description);
      search.set('enddt', endDateFormatted);
      search.set('location', locationString);
      search.set('path', '/calendar/action/compose');
      search.set('rru', 'addevent');
      search.set('startdt', startDateFormatted);
      search.set('subject', name);
      search.set('timezone', 'Australia/Sydney');

      link = `https://outlook.live.com/calendar/0/action/compose?${search.toString()}`;
      break;
    }

    case 'iCal': {
      let iCalText = '';
      iCalText += 'BEGIN:VCALENDAR\r\n';
      iCalText += 'VERSION:2.0\r\n';
      // eslint-disable-next-line quotes
      iCalText += "PRODID:-//DJ Ajax Wipe n' Spray//NONSGML v1.0//EN\r\n";
      iCalText += 'CALSCALE:GREGORIAN\r\n';
      iCalText += 'METHOD:PUBLISH\r\n';
      iCalText += 'BEGIN:VEVENT\r\n';
      iCalText += `UID:${v4().toUpperCase()}\r\n`;
      iCalText += 'CLASS:PUBLIC\r\n';
      iCalText += `DESCRIPTION:${description}\r\n`;
      iCalText += 'TZID:Australia/Sydney\r\n';
      iCalText += `DTSTAMP:${dayjs().format('YYYYMMDDTHHmmss')}\r\n`;
      iCalText += `DTSTART:${startDate.format('YYYYMMDDTHHmmss')}\r\n`;
      iCalText += `DTEND:${endDate.format('YYYYMMDDTHHmmss')}\r\n`;
      iCalText += `LOCATION:${location}\r\n`;
      iCalText += `SUMMARY:${name}\r\n`;
      iCalText += `URL:${window.location.origin}${window.location.pathname}\r\n`;
      iCalText += 'TRANSP:TRANSPARENT\r\n';
      iCalText += 'BEGIN:VALARM\r\n';
      iCalText += `X-WR-ALARMUID:${v4().toUpperCase()}\r\n`;
      iCalText += `UID:${v4().toUpperCase()}\r\n`;
      iCalText += 'TRIGGER:-PT24H\r\n';
      iCalText += 'DESCRIPTION:Reminder\r\n';
      iCalText += 'ACTION:DISPLAY\r\n';
      iCalText += 'END:VALARM\r\n';
      iCalText += 'BEGIN:VALARM\r\n';
      iCalText += `X-WR-ALARMUID:${v4().toUpperCase()}\r\n`;
      iCalText += `UID:${v4().toUpperCase()}\r\n`;
      iCalText += 'TRIGGER:-PT1H\r\n';
      iCalText += 'DESCRIPTION:Reminder\r\n';
      iCalText += 'ACTION:DISPLAY\r\n';
      iCalText += 'END:VALARM\r\n';
      iCalText += 'BEGIN:VALARM\r\n';
      iCalText += `X-WR-ALARMUID:${v4().toUpperCase()}\r\n`;
      iCalText += `UID:${v4().toUpperCase()}\r\n`;
      iCalText += 'TRIGGER:-PT0M\r\n';
      iCalText += 'DESCRIPTION:Reminder\r\n';
      iCalText += 'ACTION:DISPLAY\r\n';
      iCalText += 'END:VALARM\r\n';
      iCalText += 'END:VEVENT\r\n';
      iCalText += 'END:VCALENDAR\r\n';

      link = iCalText;
      break;
    }

    default:
    // no op
  }

  return link;
};

const downloadIcal = () => {
  const iCalText = formatCalLink(events[0], 'iCal');
  // create a blob to download the iCal file
  const blob = new Blob([iCalText], { type: 'text/calendar;charset=utf-8' });
  return URL.createObjectURL(blob);
};

const AddToCalendar = () => {
  const options = [
    {
      title: 'Google',
      className: styles.GoogleCal,
      link: formatCalLink(events[0], 'google'),
      icon: GoogleCalIcon
    },
    {
      title: 'Outlook',
      link: formatCalLink(events[0], 'outlook'),
      className: styles.Outlook,
      icon: OutlookIcon
    },
    {
      title: 'iCal',
      link: downloadIcal(),
      className: styles.iCal,
      icon: null
    }
  ];

  return (
    <div className={styles.AddToCalendar}>
      <h2>Add to Calendar</h2>
      <ul>
        {options.map((option, index) => {
          const { title, className, link, icon } = option;
          const { startDate } = events[0];

          return (
            <li key={index} className={className}>
              <a
                href={link}
                target="_blank"
                rel="noreferrer"
                download={
                  title === 'iCal'
                    ? `messy-${startDate.format('DD')}-${startDate.format('MM')}-${startDate.format('YYYY')}`
                    : 'false'
                }
              >
                {title === 'iCal' ? <ICalIcon event={events[0]} /> : <img src={icon} alt={title} />}
                <span className={styles.title}>{title}</span>
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default AddToCalendar;
