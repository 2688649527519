/**
 * Icons
 * @flow
 */
import React, { type Node } from 'react';
import TEXT_LOGO from '../../assets/dj_ajax_wipe_n_spray-website-logo.png';
import { TAGLINE } from '../../data/Data';
import Icons from '../icons/Icons';
import styles from './Footer.module.scss';

const Footer = (): Node => {
  return (
    <footer>
      <div className={styles.Logo}>
        <img src={TEXT_LOGO} className="logo" alt="Ajax Wipe n' Spray logo" />
      </div>
      <h2>{TAGLINE}</h2>
      <Icons />
      <div className={styles.Copyright}>&copy; {new Date().getFullYear()} DJ Ajax Wipe n' Spray</div>
    </footer>
  );
};

export default Footer;
