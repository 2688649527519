/**
 * Alert
 * @flow
 */
import React, { type Node } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { createEventJsonLd } from '../../data/jsonLd';
import { events } from '../../data/Data';
import dayjs from 'dayjs';
import styles from './Alert.module.scss';

const Alert = (): Node => {
  const { pathname } = useLocation();
  const { name, image, location, startDate } = events[0];

  const scrollTop = () => {
    window.scrollTo(0, 0);
  };

  return pathname === '/messy' ? null : (
    <div className={styles.AlertContainer}>
      <span className={styles.jsonLd}>{createEventJsonLd(events[0])}</span>
      <div className={styles.inner}>
        <Link to="/messy" onClick={scrollTop}>
          <img src={image} className={styles.MESSY} alt={name} />
          <div className={styles.AlertText}>{`${dayjs(startDate).format('D MMM')} @ ${location.name}`}</div>
        </Link>
      </div>
    </div>
  );
};

export default Alert;
