import BOTTLE from '../../assets/logo.png';
import styles from './Bottle.module.scss';

const Bottle = () => {
  return (
    <div className={styles.Bottle}>
      <img src={BOTTLE} className="bottle" alt="Ajax Wipe n' Spray bottle" />
    </div>
  );
};

export default Bottle;
