/**
 * Merch
 * @flow
 */
import React, { type Node } from 'react';
import Tshirt from '../t-shirt/Tshirt';
import { MERCH } from './Merch.data';
import styles from './Merch.module.scss';
type Props = {
  className?: string
};
const Merch = (props: Props): Node => {
  const { className } = props;

  return (
    <div className={`${styles.Merch}${className ? ` ${className}` : ''}`}>
      {MERCH.map((item, index) => {
        return <Tshirt key={index} {...item} />;
      })}
    </div>
  );
};

export default Merch;
