/**
 * Icons
 * @flow
 */
import React, { type Node } from 'react';
import { SvgIcon } from '@mui/material';
import Speaker from './Speaker';
import Headphones from './Headphones';
import Turntable from './Turntable';
import styles from './Icons.module.scss';

const Icons = (): Node => {
  return (
    <div className={styles.Icons}>
      <SvgIcon component={Turntable} viewBox="0 0 24 24" />
      <SvgIcon component={Headphones} viewBox="0 0 24 24" />
      <SvgIcon component={Speaker} viewBox="0 0 24 24" />
    </div>
  );
};

export default Icons;
