/**
 * Menu component
 * @flow
 */
import React, { type Node, useEffect, useState } from 'react';
import { List, ListItem, SwipeableDrawer } from '@mui/material';
import TheActualMenu from './TheActualMenu';
import { Close, Menu } from '@mui/icons-material';
import { useWindowSize } from '../../hooks/useWindowSize';
import styles from './Menu.module.scss';

const MainMenu = (): Node => {
  const { width } = useWindowSize();
  const [open, setOpen] = useState(false);
  const [paperProps, setPaperProps] = useState({
    sx: {
      background: '#333',
      width: '80vw'
    }
  });

  const modalProps = {
    sx: {
      background: 'rgba(0, 0, 0, 0.5)'
    }
  };

  useEffect(() => {
    if (width < 768) {
      setPaperProps({
        sx: {
          background: '#333',
          width: '90vw'
        }
      });
    } else {
      setPaperProps({
        sx: {
          background: '#333',
          width: '30vw'
        }
      });
    }
  }, [width]);

  const toggleDrawer = (e: SyntheticMouseEvent<any>) => {
    e.preventDefault();
    setOpen(!open);
  };

  return (
    <>
      <div className={styles.Toggle}>
        <a href="#toggleMenu" onClick={toggleDrawer}>
          <Menu />
        </a>
      </div>
      <SwipeableDrawer
        anchor="right"
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        className={styles.Menu}
        PaperProps={paperProps}
        ModalProps={modalProps}
        hideBackdrop={false}
      >
        <List>
          <ListItem divider={true}>
            <div className={styles.TopLinks}>
              <a href="#close" className={styles.InnerClose} onClick={toggleDrawer}>
                <Close />
              </a>
            </div>
          </ListItem>
          <TheActualMenu setOpen={setOpen} />
        </List>
      </SwipeableDrawer>
    </>
  );
};

export default MainMenu;
