/**
 * Messy page
 * @flow
 */
import React, { type Node } from 'react';
import { Helmet } from 'react-helmet';
import { SvgIcon } from '@mui/material';
import Header from '../../components/header/Header';
import Follow from '../../components/follow/Follow';
import Audio from '../../components/audio/Audio';
import Events from '../../components/events/Events';
import AddToCalendar from '../../components/add-to-calendar/AddToCalendar';
import Headphones from '../../components/icons/Headphones';
import SPONGE from '../../assets/sponge.png';
import TSHIRT from '../../assets/t-shirt.png';
import TEXT_LOGO from '../../assets/dj_ajax_wipe_n_spray-website-logo.png';
import { events } from '../../data/Data';
import dayjs from 'dayjs';
import { createEventJsonLd } from '../../data/jsonLd';
import BookOnlineIcon from '@mui/icons-material/BookOnline';
import Footer from '../../components/footer/Footer';
import styles from './Messy.module.scss';

const MessyPage = (): Node => {
  const {
    name,
    image,
    startDate,
    location,
    tracks,
    presenter,
    subheading,
    tickets: { url, price }
  } = events[0];

  return (
    <>
      <Helmet>
        <title>
          {presenter} presents {name} {dayjs(startDate).format('ddd D MMM YYYY')}
        </title>
        <meta name="description" content={subheading} />
      </Helmet>
      <div id="messy" className={styles.MessyPage}>
        <span className={styles.jsonLd}>{createEventJsonLd(events[0])}</span>
        <Header />
        {/* $FlowFixMe */}
        <main>
          <div className={styles.Title} itemProp="name">
            <img src={TEXT_LOGO} alt="DJ Ajax Wipe n' Spray" itemProp="logo" />
            <div className={styles.Presents}>presents</div>
            <img src={image} alt={`${presenter} presents ${name}, ${subheading}`} />
          </div>
          <div className={styles.Tickets}>
            <div className={styles.Link}>
              <a href={url} target="_blank" rel="noreferrer">
                <BookOnlineIcon /> Get tickets
              </a>
            </div>
          </div>
          <div className={styles.Tagline}>
            <h3>{subheading}</h3>
          </div>
          <div className={styles.DateTime}>
            <time dateTime={dayjs(startDate).format('YYYY-MM-DDTHH:mm')}>
              <span className={styles.Time}>{dayjs(startDate).format('ha dddd')}</span>
              <span className={styles.Date}>{dayjs(startDate).format('D MMM')}</span>
            </time>
            <AddToCalendar />
          </div>
          <div className={styles.Location}>
            <span className={styles.Name}>{name}</span>
            <img
              className={styles.Logo}
              src={location.logo}
              alt={`${presenter} presents ${name} at ${location.name}`}
            />
            <span className={styles.Address} itemProp="address">
              {location.streetAddress}, {location.addressLocality}, {location.addressRegion} {location.postalCode}
            </span>
          </div>
          <div id="listen" className={styles.Listen}>
            <div className={styles.Inner}>
              <h2>
                <SvgIcon component={Headphones} viewBox="0 0 24 24" />
                Listen
              </h2>
              <p>Listen to excerpts from recent MESSY events</p>
              <Audio slugs={tracks} />
            </div>
          </div>
          <div className={styles.Tickets}>
            <div className={styles.Text}>{price} Entry</div>
            <div className={styles.Link}>
              <a href={url} target="_blank" rel="noreferrer">
                <BookOnlineIcon /> Get tickets
              </a>
            </div>
            <div>GET</div>
            <img src={image} alt={`${presenter} presents ${name}, ${subheading}`} />
          </div>
          <div className={styles.DateTime}>
            <time dateTime={dayjs(startDate).format('YYYY-MM-DDTHH:mm')}>
              <span className={styles.Time}>{dayjs(startDate).format('ha dddd')}</span>
              <span className={styles.Date}>{dayjs(startDate).format('D MMM')}</span>
            </time>
            <AddToCalendar />
          </div>
          <div className={styles.Description}>
            <p>Booty shaking house and techno all night long from your friendly gaybourhood DJ Ajax Wipe n' Spray.</p>
            <p>Free entry all night long</p>
            <p>The monthly residency continues at UBQ for the rest of 2024.</p>
            <p>Come down and get MESSY</p>
          </div>
          <div className={styles.Giveaways}>
            <div className={styles.Text}>Sponge and t-shirt giveaways</div>
            <img className={styles.Sponge} src={SPONGE} alt="Sponge giveaway" />
            <img className={styles.TShirt} src={TSHIRT} alt="Ajax Wipe n' Spray t-shirt giveaway" />
          </div>
          <div className={styles.Styles}>
            <div className={styles.House}>Tech House &</div>
            <div className={styles.Techno}>Techno</div>
            <div className={styles.AllNight}>All night long</div>
          </div>
          <Events shift={true} />
          <Follow />
        </main>
        <Footer />
      </div>
    </>
  );
};

export default MessyPage;
