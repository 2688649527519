/**
 * Tracks page
 * @flow
 */
import React, { type Node } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { LINKS } from '../../data/Data';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import styles from './Linktree.module.scss';

const LinktreePage = (): Node => {
  return (
    <>
      <Helmet>
        <title>DJ Ajax Wipe n' Spray | Links</title>
        <meta name="description" content="Links for DJ Ajax Wipe n' Spray" />
      </Helmet>

      <div className={styles.Linktree}>
        <Header />
        {/* $FlowFixMe */}
        <main>
          <h1>Links</h1>
          <ul>
            {LINKS.map((link, index) => {
              const { title, path, icon, location } = link;
              if (location.indexOf('linktree') === -1) {
                return null;
              }
              return (
                <li key={index}>
                  {/^http/.test(link.path) ? (
                    <a href={path} target="_blank" rel="noreferrer">
                      {icon} {title}
                    </a>
                  ) : (
                    <Link to={path}>
                      {icon} {title}
                    </Link>
                  )}
                </li>
              );
            })}
          </ul>
        </main>
        <Footer />
      </div>
    </>
  );
};

export default LinktreePage;
