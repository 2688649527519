import React from 'react';
import { LINKS } from '../../data/Data';
import styles from './Follow.module.scss';

const Follow = () => {
  return (
    <div className={styles.Follow}>
      <h2>FOLLOW</h2>
      <ul>
        {LINKS.map((link, index) => {
          const { title, path, icon, location } = link;

          return location.indexOf('follow') === -1 ? null : (
            <li key={index} className={styles[`link-${index}`]}>
              <a href={path} target="_blank" rel="noreferrer">
                {icon} <span className={styles.title}>{title}</span>
              </a>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default Follow;
