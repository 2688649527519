/**
 * Track
 * @flow
 */
import React, { useRef, useState, useEffect, type Node } from 'react';
import { Link } from 'react-router-dom';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import TEXT_LOGO from '../../assets/dj_ajax_wipe_n_spray-website-logo.png';
import { createTrackJsonLd } from '../../data/jsonLd';
import styles from './Track.module.scss';
import type { Track } from '../../types/Types';

type Props = {
  onPlay: (track: Track) => null | void,
  onEnded: (track: Track) => null,
  track: Track,
  index: number,
  playing: Track | null,
  single: boolean,
  hide: boolean
};

const Audio = (props: Props): Node => {
  const { onPlay, onEnded, track, playing, single, hide } = props;
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [duration, setDuration] = useState<string>('0');
  const { src, title, image, tag, links, slug } = track;
  const trackRef = useRef(null);

  useEffect(() => {
    if (trackRef && trackRef.current !== null && playing) {
      if (playing.src === src) {
        trackRef.current.play();
        setIsPlaying(true);
      } else if (isPlaying) {
        trackRef.current.pause();
        setIsPlaying(false);
      }
    }
  }, [playing]);

  const onTrackEnd = () => {
    if (trackRef && trackRef.current !== null) {
      trackRef.current.pause();
      trackRef.current.currentTime = 0;
      setIsPlaying(false);
    }

    onEnded(track);
  };

  /**
   * On play of track
   */
  const onPlayTrack = () => {
    onPlay(track);

    window.dataLayer.push({
      event: 'playTrack',
      eventCategory: 'Audio',
      eventAction: 'play',
      eventLabel: title
    });
  };

  const onLoadedMetadata = () => {
    if (trackRef && trackRef.current) {
      const { duration } = trackRef.current;
      let iso8601 = '';
      // duration is in seconds. convert to ISO8601 hours minutes seconds
      switch (true) {
        case duration < 60: {
          // < 1 minute
          const minutes = 0;
          const seconds = Math.floor(duration);
          iso8601 = `${minutes}M${seconds}S`;
          break;
        }

        case duration < 60 * 60: {
          // < 1 hour
          const minutes = Math.floor(duration / 60);
          const seconds = Math.floor(duration - minutes * 60);
          iso8601 = `${minutes}M${seconds}S`;
          break;
        }

        case duration < 60 * 60 * 12: {
          // < 12 hours
          const hours = Math.floor(duration / (60 * 60));
          const minutes = Math.floor((duration - hours * 60 * 60) / 60);
          const seconds = Math.floor(duration - hours * 60 * 60 - minutes * 60);
          iso8601 = `${hours}H${minutes}M${seconds}S`;
          break;
        }
      }

      setDuration(`PT${iso8601}`);
    }
  };

  return hide ? (
    <span className={styles.trackJsonLd}>{createTrackJsonLd(track, duration)}</span>
  ) : (
    <li className={styles.Track}>
      <span className={styles.trackJsonLd}>{createTrackJsonLd(track, duration)}</span>
      <div className={styles.Image}>
        {image ? <img src={image} alt={title} /> : <img src={TEXT_LOGO} alt={title} />}
      </div>
      <div className={styles.Heading}>
        <h3>{title}</h3>
        <div className={styles.Tag}>{tag}</div>
      </div>
      {/** $FlowFixMe */}
      <audio
        ref={trackRef}
        controls
        src={src}
        onPlay={onPlayTrack}
        onEnded={onTrackEnd}
        onLoadedMetadata={onLoadedMetadata}
      />
      {!single && links && links.length ? (
        <div className={styles.LinksContainer}>
          <ul className={styles.Links}>
            {links.map((link, index) => {
              const { title, path, icon } = link;
              return (
                <li key={index}>
                  <a
                    href={path}
                    className={styles[title.replaceAll(' ', '')]}
                    target="_blank"
                    rel="noopener noreferrer"
                    title={title}
                  >
                    {icon}
                  </a>
                </li>
              );
            })}
            {slug ? (
              <li>
                <Link to={`/tracks/${slug}`} className={styles.SingleTrackPage} title={title}>
                  <MoreHorizIcon />
                </Link>
              </li>
            ) : null}
          </ul>
        </div>
      ) : null}
    </li>
  );
};

Audio.defaultProps = {
  playing: { src: '', title: '' },
  onPlay: (): null => null,
  onEnded: (): null => null,
  index: 0,
  track: { src: '', title: '' },
  single: false,
  hide: false
};

export default Audio;
