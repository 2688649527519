/**
 * Contact page
 * @flow
 */
import React, { type Node } from 'react';
import { Helmet } from 'react-helmet';
import Header from '../../components/header/Header';
import Merch from '../../components/merch/Merch';
import Footer from '../../components/footer/Footer';
import styles from './Merch.module.scss';

const MerchPage = (): Node => {
  return (
    <>
      <Helmet>
        <title>DJ Ajax Wipe n' Spray | Merch</title>
        <meta name="description" content="Merch for DJ Ajax Wipe n' Spray" />
      </Helmet>

      <div id="contact" className={styles.MerchPage}>
        <Header />
        {/* $FlowFixMe */}
        <main>
          <h1>Merch</h1>
          <Merch className={styles.MerchPageMerch} />
        </main>
        <Footer />
      </div>
    </>
  );
};

export default MerchPage;
