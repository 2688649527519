/**
 * Turntable Icon
 * @flow
 */
import React, { type Node } from 'react';
import styles from './Icons.module.scss';

const Turntable = (): Node => {
  return (
    <svg viewBox="0 0 7.6785612 7.6785612" version="1.1" id="svg1671" className={styles.Icon}>
      <defs id="defs1668" />
      <g id="layer1" transform="translate(-147.90431,-25.212002)">
        <g id="g403" transform="matrix(0.35277777,0,0,-0.35277777,148.0807,29.051282)" style={{ display: 'inline' }}>
          <path
            d="m 0,0 c 0,5.734 4.648,10.383 10.383,10.383 5.734,0 10.383,-4.649 10.383,-10.383 0,-5.734 -4.649,-10.383 -10.383,-10.383 C 4.648,-10.383 0,-5.734 0,0"
            style={{
              display: 'inline',
              fill: '#000000',
              fillOpacity: 1,
              fillRule: 'nonzero',
              stroke: 'none'
            }}
            id="path405"
          />
        </g>
        <g id="g407" transform="matrix(0.35277777,0,0,-0.35277777,148.0807,29.051282)" style={{ display: 'inline' }}>
          <path
            d="m 0,0 c 0,5.734 4.648,10.383 10.383,10.383 5.734,0 10.383,-4.649 10.383,-10.383 0,-5.734 -4.649,-10.383 -10.383,-10.383 C 4.648,-10.383 0,-5.734 0,0 Z"
            style={{
              fill: 'none',
              stroke: '#ffffff',
              strokeWidth: 1,
              strokeLinecap: 'butt',
              strokeLinejoin: 'miter',
              strokeMiterlimit: 10,
              strokeDasharray: 'none',
              strokeOpacity: 1
            }}
            id="path409"
          />
        </g>
        <g id="g427" transform="matrix(0.35277777,0,0,-0.35277777,149.56554,29.051282)" style={{ display: 'inline' }}>
          <path
            d="m 0,0 c 0,3.41 2.764,6.174 6.174,6.174 3.41,0 6.175,-2.764 6.175,-6.174 0,-3.41 -2.765,-6.175 -6.175,-6.175 C 2.764,-6.175 0,-3.41 0,0"
            style={{
              fill: '#ffffff',
              fillOpacity: 1,
              fillRule: 'nonzero',
              stroke: 'none'
            }}
            id="path429"
          />
        </g>
        <g id="g431" transform="matrix(0.35277777,0,0,-0.35277777,150.94081,29.051282)" style={{ display: 'inline' }}>
          <path
            d="M 0,0 C 0,1.257 1.019,2.275 2.275,2.275 3.532,2.275 4.551,1.257 4.551,0 4.551,-1.257 3.532,-2.275 2.275,-2.275 1.019,-2.275 0,-1.257 0,0"
            style={{
              fill: '#000000',
              fillOpacity: 1,
              fillRule: 'nonzero',
              stroke: 'none'
            }}
            id="path433"
          />
        </g>
        <g id="g435" transform="matrix(0.35277777,0,0,-0.35277777,151.56198,29.232857)" style={{ display: 'inline' }}>
          <path
            d="m 0,0 c -0.284,0.284 -0.284,0.745 0,1.029 0.284,0.284 0.745,0.284 1.029,0 C 1.313,0.745 1.313,0.284 1.029,0 0.745,-0.284 0.284,-0.284 0,0"
            style={{
              fill: '#ffffff',
              fillOpacity: 1,
              fillRule: 'nonzero',
              stroke: 'none'
            }}
            id="path437"
          />
        </g>
        <g id="g439" transform="matrix(0.35277777,0,0,-0.35277777,151.77351,30.556091)" style={{ display: 'inline' }}>
          <path
            d="M 0,0 C 0,0 3.487,-0.165 4.381,4.266"
            style={{
              fill: 'none',
              stroke: '#000000',
              strokeWidth: 2.126,
              strokeLinecap: 'round',
              strokeLinejoin: 'miter',
              strokeMiterlimit: 10,
              strokeDasharray: 'none',
              strokeOpacity: 1
            }}
            id="path441"
          />
        </g>
      </g>
    </svg>
  );
};

export default Turntable;
