/**
 * Header
 * @flow
 */
import React, { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import { useMatches } from 'react-router-dom';
import TEXT_LOGO from '../../assets/dj_ajax_wipe_n_spray-website-logo.png';
import Icons from '../icons/Icons';
import Menu from '../menu/Menu';
import Alert from '../alert/Alert';
import styles from './Header.module.scss';

const Header: React$AbstractComponent<any, any> = forwardRef(function Header(props, ref) {
  const matches = useMatches('/');

  return (
    <header ref={ref} className={styles.Header}>
      <div className={styles.inner}>
        {matches[0].pathname === '/' ? (
          <img src={TEXT_LOGO} className="logo" alt="DJ Ajax Wipe n' Spray logo" />
        ) : (
          <Link to="/">
            <img src={TEXT_LOGO} className="logo" alt="DJ Ajax Wipe n' Spray logo" />
          </Link>
        )}
        <Icons />
        <Menu />
      </div>
      <Alert />
    </header>
  );
});

export default Header;
